export const fetchRackets = async () => {
  //const response = await fetch('http://localhost:3001/api/rackets');
  //const exchangeRatesResponse = await fetch('http://localhost:3001/api/exchange-rates');
  const response = await fetch('https://rackets.top/api/rackets');
  const exchangeRatesResponse = await fetch('https://rackets.top/api/exchange-rates');
  const exchangeRatesData = await exchangeRatesResponse.json();
  const data = await response.json();

  const racketsWithPricesInEUR = data.map(racket => {
    racket.prices = racket.prices.map(price => {
      const exchangeRate = exchangeRatesData.rates[price.currency];
      const priceInEUR = (price.amount / exchangeRate).toFixed(2);
      return {
        ...price,
        amountInEUR: parseFloat(priceInEUR),
      };
    });

    const lowestPriceData = racket.prices.reduce((min, price) => {
      return price.amountInEUR < min.amountInEUR ? price : min;
    }, racket.prices[0]);

    if (lowestPriceData) {
      racket.lowestPrice = {
        amount: parseFloat(lowestPriceData.amountInEUR.toFixed(2)),
        currency: 'EUR',
        originalAmount: lowestPriceData.amount,
        originalCurrency: lowestPriceData.currency
      };
    }

    return racket;
  });

  const randomizedRackets = racketsWithPricesInEUR.sort(() => Math.random() - 0.5);

  return randomizedRackets;
};
