import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import RacketList from './components/RacketList';
import RacketDetails from './components/RacketDetails';
import RacketFinder from './components/RacketFinder';
import Header from './components/Header';
import Footer from './components/Footer';
import { BreadcrumbProvider } from './BreadcrumbContext';
import { fetchRackets } from './api';
import ScrollToTop from './components/ScrollToTop';
import './i18n';
import RacketComparator from './components/RacketComparator';

function App() {
  const [rackets, setRackets] = useState([]);

  useEffect(() => {
    const loadRackets = async () => {
      const data = await fetchRackets();
      setRackets(data);
    };

    loadRackets();
  }, []);

  return (
    <BreadcrumbProvider>
      <Router>
        <ScrollToTop />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/badminton/racket-finder" element={<RacketFinder rackets={rackets}/>} />
            <Route path="/badminton/racket-comparator" element={<RacketComparator rackets={rackets}/>} />
            <Route path="/badminton/rackets" element={<RacketList rackets={rackets} />} />
            <Route path="/badminton/racket/:uuid" element={<RacketDetails rackets={rackets} />} />
          </Routes>
        </Layout>
      </Router>
    </BreadcrumbProvider>
  );
}

function Layout({ children }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      {!isHomePage && <Header />}
      {children}
      {!isHomePage && <Footer />}
    </>
  );
}

export default App;
